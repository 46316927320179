<template>
  <div class="p-6 w-100">
    <form @submit.prevent="handleValidate" class="wrap-form">
      <b-card>        
        <b-row>
          <b-col cols="6">
            <basic-input
              label="Tên đăng nhập"
              required
              placeholder="Nhập tên đăng nhập"
              name="username"
              v-validate="{
                required: true,
                alpha_num_dot: true,
                max: 30,
              }"
              :state="validateState('username')"
              :invalidFeedback="errors.first('username')"
              data-vv-as="Tên đăng nhập"
              maxlength="30"
              v-model="form.username"
              :disabled="isUpdate"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Tên nhân viên"
              required
              placeholder="Nhập tên nhân viên"
              name="name"
              v-validate="'required|max:100'"
              :state="validateState('name')"
              :invalidFeedback="errors.first('name')"
              data-vv-as="Tên nhân viên"
              maxlength="100"
              v-model="form.name"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Trạng thái"
              placeholder="--- Chọn trạng thái ---"
              track-by="value"
              value-label="text"
              :options="statuses"
              v-model="form.status"
              :allow-empty="false"
              disabled
              required
              name="status"
              changeValueByObject
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Mật khẩu"
              required
              placeholder="Nhập mật khẩu"
              name="password"
              disabled
              v-model="form.password"
              :inputType="isUpdate ? 'password' : 'text'"
            />
          </b-col>
          <b-col cols="6">
            <b-form-group
              :id="`brithday-group`"
              label="Ngày sinh"
              :invalid-feedback="errors.first('brithday')"
              :state="validateState('brithday')"
              class="required"
              label-for="brithday-ID"
            >
              <date-picker
                id="brithday-ID"
                placeholder="--- Chọn ngày sinh ---"
                name="brithday"
                input-class="form-control"
                type="date"
                format="DD/MM/YYYY"
                value-type="format"
                :disabled-date="disabledAfterToday"
                v-model="form.brithday"
                data-vv-as="Ngày sinh"
                v-validate="'required'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Giới tính"
              placeholder="--- Chọn giới tính ---"
              track-by="value"
              value-label="text"
              :options="sexes"
              :value.sync="form.sex"
              :allow-empty="false"
              required
              name="sex-1"
              v-validate="'required'"
              :state="validateState('sex-1')"
              :invalidFeedback="errors.first('sex-1')"
              data-vv-as="Giới tính"
              changeValueByObject
              @update:value="resetDropdown('sex-1')"
            />
          </b-col>
          <b-col cols="12">
            <basic-text-area
              label="Lời giới thiệu"
              required
              placeholder="Nhập nội dung giới thiệu"
              v-validate="'required|max:500'"
              :state="validateState('introduction')"
              :invalidFeedback="errors.first('introduction')"
              data-vv-as="Lời giới thiệu"
              name="introduction"
              maxlength="500"
              :value.sync="form.introduction"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Email"
              required
              placeholder="Nhập email"
              name="email"
              v-validate="'required|email|max:50'"
              :state="validateState('email')"
              :invalidFeedback="errors.first('email')"
              data-vv-as="Email"
              v-model="form.email"
              maxlength="50"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Số điện thoại 1"
              required
              placeholder="Nhập số điện thoại 1"
              name="firstPhoneNumber"
              v-validate="'required|phone'"
              :state="validateState('firstPhoneNumber')"
              :invalidFeedback="errors.first('firstPhoneNumber')"
              data-vv-as="Số điện thoại 1"
              maxlength="10"
              v-model="form.firstPhoneNumber"
              v-numeric-input
              code="+84"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Số điện thoại 2"
              placeholder="Nhập số điện thoại 2"
              name="secondPhoneNumber"
              v-validate="{
                phone: true,
                duplicate_phone: form.firstPhoneNumber,
              }"
              :state="validateState('secondPhoneNumber')"
              :invalidFeedback="errors.first('secondPhoneNumber')"
              data-vv-as="Số điện thoại 2"
              maxlength="10"
              v-model="form.secondPhoneNumber"
              v-numeric-input
              code="+84"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Facebook"
              placeholder="Nhập link Facebook"
              name="facebookUrl"
              v-model="form.facebookUrl"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="URL"
              placeholder="Nhập URL khác (nếu có)"
              name="url"
              v-model="form.url"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Vị trí"
              placeholder="--- Chọn vị trí ---"
              track-by="value"
              value-label="text"
              :options="positions"
              :value.sync="form.position"
              allow-empty
              taggable
              required
              changeValueByObject
              tag-placeholder="Thêm vị trí"
              name="position"
              v-validate="'required'"
              :state="validateState('position')"
              :invalidFeedback="errors.first('position')"
              data-vv-as="Vị trí"
              :disabled="diableInputWithPosition"
              :maxlengthTag="50"
              @tag="addTags($event, 'positions', 'position')"
              @update:value="resetDropdown('position')"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Quốc gia"
              placeholder="--- Chọn quốc gia ---"
              track-by="id"
              value-label="name"
              :options="countries"
              :value.sync="form.country"
              :allowEmpty="false"
              required
              :apiPath="'/Division/Nations'"
              :searchField="'searchTerm'"
              name="country"
              changeValueByObject
              v-validate="'required'"
              :state="validateState('country')"
              :invalidFeedback="errors.first('country')"
              data-vv-as="Quốc gia"
              @update:value="resetDropdown('country')"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Tỉnh thành"
              placeholder="--- Chọn tỉnh thành ---"
              track-by="id"
              value-label="name"
              :options="provinces"
              :value.sync="form.province"
              :apiPath="'/Division/Provinces'"
              :searchField="'searchTerm'"
              :searchParams="{ nationId: form.country && form.country.id }"
              name="province"
              changeValueByObject
              :allowEmpty="false"
              required
              v-validate="'required'"
              :state="validateState('province')"
              :invalidFeedback="errors.first('province')"
              data-vv-as="Tỉnh thành"
              @update:value="selectProvince"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Quận / Huyện"
              placeholder="--- Chọn quận / huyện ---"
              track-by="id"
              value-label="name"
              :options="districts"
              :value.sync="form.district"
              :allow-empty="false"
              name="district"
              changeValueByObject
              :apiPath="'/Division/Dictricts'"
              :searchField="'searchTerm'"
              :searchParams="{ provinceId: form.province && form.province.id }"
              @update:value="selectDistrict"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Phường / Xã"
              placeholder="--- Chọn phường / xã ---"
              track-by="id"
              value-label="name"
              :options="wards"
              :value.sync="form.ward"
              :allowEmpty="false"
              name="ward"
              changeValueByObject
              :apiPath="'/Division/Wards'"
              :searchField="'searchTerm'"
              :searchParams="{ districtId: form.district && form.district.id }"
              @update:value="resetDropdown('ward')"
            />
          </b-col>
          <b-col cols="6">
            <basic-input
              label="Địa chỉ"
              placeholder="Nhập địa chỉ"
              name="address"
              v-validate="'max:200'"
              :state="validateState('address')"
              :invalidFeedback="errors.first('address')"
              data-vv-as="Địa chỉ"
              v-model="form.address"
              maxlength="200"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Vai trò"
              placeholder="--- Chọn vai trò ---"
              track-by="value"
              value-label="text"
              :options="roles"
              :value.sync="form.role"
              required
              multiple
              name="role"
              v-validate="'required'"
              :state="validateState('role')"
              :invalidFeedback="errors.first('role')"
              data-vv-as="Vai trò"
              changeValueByObject
              :disabled="diableInputWithPosition"
              @update:value="resetDropdown('role')"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              label="Agency"
              placeholder="--- Chọn agency ---"
              track-by="value"
              value-label="text"
              :options="agencies"
              :value.sync="form.agency"
              allow-empty
              taggable
              changeValueByObject
              name="agency"
              tag-placeholder="Thêm agency"
              :maxlengthTag="100"
              :disabled="diableInputWithPosition"
              @tag="addTags($event, 'agencies', 'agency')"
              @update:value="resetDropdown('agency')"
            />
          </b-col>
          <template v-if="showAccountRules">
            <b-col cols="6">
              <basic-input
                label="Học vấn"
                required
                placeholder="Nhập bằng cấp cao nhất"
                name="qualification"
                v-validate="'required|max:100'"
                :state="validateState('qualification')"
                :invalidFeedback="errors.first('qualification')"
                data-vv-as="Học vấn"
                v-model="form.qualification"
                maxlength="100"
                :disabled="diableInputWithPosition"
              />
            </b-col>
            <b-col cols="6">
              <basic-select
                label="Phân cấp"
                placeholder="--- Chọn cấp độ ---"
                track-by="value"
                value-label="text"
                :options="levels"
                :value.sync="form.level"
                required
                name="level"
                changeValueByObject
                v-validate="'required'"
                :state="validateState('level')"
                :invalidFeedback="errors.first('level')"
                data-vv-as="Phân cấp"
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('level')"
              />
            </b-col>
            <b-col cols="6">
              <basic-select
                label="Phân loại"
                placeholder="--- Chọn hình thức làm việc ---"
                track-by="value"
                value-label="text"
                :options="typesOfWork"
                :value.sync="form.typeOfWork"
                required
                name="typeOfWork"
                changeValueByObject
                v-validate="'required'"
                :state="validateState('typeOfWork')"
                :invalidFeedback="errors.first('typeOfWork')"
                data-vv-as="Phân loại"
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('typeOfWork')"
              />
            </b-col>
          </template>
          <b-col cols="6">
            <basic-input
              label="Mã giới thiệu"
              name="referralCode"
              v-model="form.referralCode"
              v-validate="{
                min: 6,
              }"
              :state="validateState('referralCode')"
              :invalidFeedback="errors.first('referralCode')"
              data-vv-as="Mã giới thiệu"
              maxlength="6"
              :debounce="500"
              @v-modal:update="searchDebounce"
            />
          </b-col>
          <b-col cols="6">
            <basic-select
              v-if="showManagementCoachs"
              label="Coach manager"
              placeholder="--- Chọn người quản lý ---"
              track-by="value"
              value-label="text"
              :options="coaches"
              :value.sync="form.coachManager"
              required
              name="coach"
              v-validate="'required'"
              :state="validateState('coach')"
              :invalidFeedback="errors.first('coach')"
              data-vv-as="Coach đang quản lý"
              changeValueByObject
              :disabled="diableInputWithPosition"
              @update:value="resetDropdown('coach')"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <template v-if="showAccountRules">
          <h6>Đánh giá tính cách và tiêu chí chọn học viên</h6>
          <b-row>
            <b-col>
              <label>Độ tuổi <span class="text-danger">*</span></label>
              <b-row>
                <b-col>
                  <basic-select
                    placeholder="--- Từ ---"
                    track-by="value"
                    value-label="text"
                    :options="startAges"
                    :value.sync="form.startAge"
                    required
                    :allow-empty="false"
                    name="startAge"
                    v-validate="{
                      required: true,
                    }"
                    :state="validateState('startAge')"
                    :invalidFeedback="errors.first('startAge')"
                    data-vv-as="Độ tuổi từ"
                    changeValueByObject
                    :disabled="diableInputWithPosition"
                    @update:value="selectedStartAge"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    placeholder="--- Đến ---"
                    track-by="value"
                    value-label="text"
                    :options="endAges"
                    :value.sync="form.endAge"
                    required
                    :allow-empty="false"
                    name="endAge"
                    v-validate="{
                      required: true,
                    }"
                    :state="validateState('endAge')"
                    :invalidFeedback="errors.first('endAge')"
                    data-vv-as="Độ tuổi đến"
                    changeValueByObject
                    :disabled="diableInputWithPosition"
                    @update:value="selectedEndAge"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <basic-select
                label="Giới tính"
                placeholder="--- Chọn giới tính ---"
                track-by="value"
                value-label="text"
                :options="sexes"
                :value.sync="form.selectedSexes"
                :allow-empty="true"
                required
                multiple
                name="sex-2"
                v-validate="'required'"
                :state="validateState('sex-2')"
                :invalidFeedback="errors.first('sex-2')"
                data-vv-as="Giới tính"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('sex-2')"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Số lượng học viên"
                placeholder="--- Chọn số lượng học viên ---"
                track-by="value"
                value-label="text"
                :options="memberQuantities"
                :value.sync="form.memberQuantity"
                :allow-empty="false"
                required
                name="memberQuantity"
                v-validate="'required'"
                :state="validateState('memberQuantity')"
                :invalidFeedback="errors.first('memberQuantity')"
                data-vv-as="Số lượng học viên"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('memberQuantity')"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Tính cách"
                placeholder="--- Chọn tính cách ---"
                track-by="value"
                value-label="text"
                :options="personalities"
                :value.sync="form.selectedPersonalities"
                :allow-empty="true"
                required
                multiple
                name="personality"
                v-validate="'required'"
                :state="validateState('personality')"
                :invalidFeedback="errors.first('personality')"
                data-vv-as="Tính cách"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('personality')"
                @input="selectOptions($event, 'personality', personalities)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Sở thích cá nhân"
                placeholder="--- Chọn sở thích ---"
                track-by="value"
                value-label="text"
                :options="interests"
                :value.sync="form.selectedInterests"
                allow-empty
                required
                multiple
                name="interests"
                v-validate="'required'"
                :state="validateState('interests')"
                :invalidFeedback="errors.first('interests')"
                data-vv-as="Sở thích cá nhân"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('interests')"
                @input="selectOptions($event, 'interests', interests)"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Môn thể thao yêu thích"
                placeholder="--- Chọn môn thể thao ---"
                track-by="value"
                value-label="text"
                :options="sports"
                :value.sync="form.selectedSports"
                allow-empty
                required
                multiple
                taggable
                tag-placeholder="Thêm môn thể thao"
                name="sport"
                changeValueByObject
                :disabled="diableInputWithPosition"
                v-validate="'required'"
                :maxlengthTag="100"
                :state="validateState('sport')"
                :invalidFeedback="errors.first('sport')"
                data-vv-as="Môn thể thao yêu thích"
                @tag="addMultipleTags($event, 'sports', 'selectedSports')"
                @update:value="resetDropdown('sport')"
                @input="selectOptions($event, 'sport', sports)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Thực hành tâm thức"
                placeholder="--- Chọn thực hành tâm thức ---"
                track-by="value"
                value-label="text"
                :options="consciousnesses"
                :value.sync="form.selectedConsciousnesses"
                allow-empty
                required
                multiple
                name="consciousness"
                v-validate="'required'"
                :state="validateState('consciousness')"
                :invalidFeedback="errors.first('consciousness')"
                data-vv-as="Thực hành tâm thức"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('consciousness')"
                @input="selectOptions($event, 'consciousness', consciousnesses)"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Tính địa phương"
                placeholder="--- Chọn tính địa phương --- "
                track-by="value"
                value-label="text"
                :options="regions"
                :value.sync="form.selectedRegions"
                :allow-empty="true"
                required
                multiple
                taggble
                name="regions"
                v-validate="'required'"
                :state="validateState('regions')"
                :invalidFeedback="errors.first('regions')"
                data-vv-as="Tính địa phương"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('regions')"
                @input="selectOptions($event, 'regions', regions)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Ngôn ngữ"
                placeholder="--- Chọn ngôn ngữ ---"
                track-by="value"
                value-label="text"
                :options="languages"
                :value.sync="form.selectedLanguages"
                allow-empty
                required
                multiple
                name="language"
                v-validate="'required'"
                :state="validateState('language')"
                :invalidFeedback="errors.first('language')"
                data-vv-as="Ngôn ngữ"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('language')"
                @input="selectOptions($event, 'language', languages)"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Tôn giáo"
                placeholder="--- Chọn tôn giáo --- "
                track-by="value"
                value-label="text"
                :options="religions"
                :value.sync="form.selectedReligions"
                allow-empty
                required
                multiple
                taggable
                tag-placeholder="Thêm tôn giáo"
                name="religions"
                v-validate="'required'"
                :state="validateState('religions')"
                :invalidFeedback="errors.first('religions')"
                data-vv-as="Tôn giáo"
                changeValueByObject
                :maxlengthTag="100"
                :disabled="diableInputWithPosition"
                @tag="addMultipleTags($event, 'religions', 'selectedReligions')"
                @update:value="resetDropdown('religions')"
                @input="selectOptions($event, 'religions', religions)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Ăn chay"
                placeholder="--- Chọn Ăn chay ---"
                track-by="value"
                value-label="text"
                :options="vegetarians"
                :value.sync="form.selectedVegetarians"
                allow-empty
                required
                multiple
                name="vegetarian"
                v-validate="'required'"
                :state="validateState('vegetarian')"
                :invalidFeedback="errors.first('vegetarian')"
                data-vv-as="Ăn chay"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('vegetarian')"
                @input="selectOptions($event, 'vegetarian', vegetarians)"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Khung giờ làm việc"
                placeholder="--- Chọn khung giờ làm việc --- "
                track-by="value"
                value-label="text"
                :options="workingTimes"
                :value.sync="form.selectedWorkingTimes"
                allow-empty
                required
                multiple
                name="workingTime"
                v-validate="'required'"
                :state="validateState('workingTime')"
                :invalidFeedback="errors.first('workingTime')"
                data-vv-as="Khung giờ làm việc"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('workingTime')"
                @input="selectOptions($event, 'workingTime', workingTimes)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-select
                label="Mức độ đái tháo đường"
                placeholder="--- Chọn mức độ ---"
                track-by="value"
                value-label="text"
                :options="diabetesLevels"
                :value.sync="form.selectedDiabetesLevels"
                allow-empty
                required
                multiple
                name="diabetesLevels"
                v-validate="'required'"
                :state="validateState('diabetesLevels')"
                :invalidFeedback="errors.first('diabetesLevels')"
                data-vv-as="Mức độ đái tháo đường"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('diabetesLevels')"
                @input="selectOptions($event, 'diabetesLevels', diabetesLevels)"
              />
            </b-col>
            <b-col>
              <basic-select
                label="Kinh nghiệm chuyên sâu"
                placeholder="--- Chọn kinh nghiệm --- "
                track-by="value"
                value-label="text"
                :options="experiences"
                :value.sync="form.selectedExperiences"
                allow-empty
                required
                multiple
                name="experience"
                v-validate="'required'"
                :state="validateState('experience')"
                :invalidFeedback="errors.first('experience')"
                data-vv-as="Kinh nghiệm chuyên sâu"
                changeValueByObject
                :disabled="diableInputWithPosition"
                @update:value="resetDropdown('experience')"
                @input="selectOptions($event, 'experience', experiences)"
              />
            </b-col>
          </b-row>
        </template>
        <div v-if="showAccountRules && isUpdate" class="blank"></div>
        <div v-if="isUpdate">
          <h5>QR Code</h5>
          <b-row>
            <b-col>
              <div class="text-center">
                <canvas
                  id="qr-code"
                  ref="qrcodeCanvas"
                  class="share-qrcode__canvas"
                ></canvas>
              </div>
              <div class="text-center mt-6">
                <b-button
                  class="btn btn-success"
                  type="button"
                  @click="downloadQrCode"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/download.svg"
                    />
                  </span>
                  Download QR code (.PNG)
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </form>
  </div>
</template>
<script>
import intersectionWith from 'lodash/intersectionWith';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import QRCode from 'qrcode';
import intersectionBy from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';
import {
  MODAL_STATUS,
  ACCOUNT_RULE_TYPE,
  TAG_TYPE,
} from '@/core/plugins/constants';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'FormCoachProfile',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      positions: [],
      statuses: [
        {
          name: 'Active',
          value: 1,
        },
        {
          name: 'Inactive',
          value: 0,
        },
        {
          name: 'Draft',
          value: 2,
        },
      ],
      sexes: [],
      levels: [],
      typesOfWork: [],
      coaches: [],
      provinces: [],
      countries: [],
      districts: [],
      wards: [],
      roles: [],
      agencies: [],
      generalAges: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      startAges: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      endAges: Array.from({ length: 101 }, (_, i) => {
        return {
          text: `${i}`,
          value: `${i}`,
        };
      }),
      memberQuantities: Array.from({ length: 70 }, (_, i) => {
        return {
          text: `${i + 1}`,
          value: `${i + 1}`,
        };
      }),
      personalities: [],
      interests: [],
      sports: [],
      consciousnesses: [],
      regions: [],
      religions: [],
      languages: [],
      vegetarians: [],
      workingTimes: [],
      diabetesLevels: [],
      experiences: [],
      file: null,
      preview: null,
      isImageValid: true,
      isImage: true,
      accountRuleId: null,
      form: {
        status: {
          text: 'Active',
          value: 1,
        },
        name: null,
        username: null,
        password: this.generateReferralCode(6)[0],
        brithday: null,
        sex: null,
        introduction: null,
        firstPhoneNumber: null,
        secondPhoneNumber: null,
        facebookUrl: null,
        email: null,
        url: null,
        position: null,
        country: null,
        province: null,
        district: null,
        ward: null,
        address: null,
        role: [],
        agency: null,
        qualification: null,
        typeOfWork: null,
        level: null,
        referralCode: null,
        coachManager: null,
        startAge: {
          text: '0',
          value: '0',
        },
        endAge: {
          text: '0',
          value: '0',
        },
        selectedSexes: [],
        memberQuantity: null,
        selectedPersonalities: [],
        selectedInterests: [],
        selectedSports: [],
        selectedConsciousnesses: [],
        selectedRegions: [],
        selectedReligions: [],
        selectedLanguages: [],
        selectedVegetarians: [],
        selectedWorkingTimes: [],
        selectedDiabetesLevels: [],
        selectedExperiences: [],
      },
    };
  },
  watch: {
    detail: {
      handler() {
        this.setupData();
      },
      deep: true,
    },
    'form.referralCode': {
      handler() {
        this.generateQrCode();
      },
      deep: true,
    },
  },
  computed: {
    showAccountRules() {
      if (
        this.form.position &&
        ['Coach', 'Head Coach', 'Coach Manager'].includes(
          this.form.position.text,
        )
      )
        return true;
      return false;
    },
    showManagementCoachs() {
      if (this.form.position && this.form.position.text === 'Coach')
        return true;
      return false;
    },
    isAdmin() {
      return this.$route.meta.isWriteAllPermission;
    },
    isCoach() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    diableInputWithPosition() {
      if (this.isAdmin) return !this.isAdmin;

      return this.isCoach;
    },
  },
  created() {
    this.setupData();
  },
  mounted() {
    this.generateQrCode();
  },
  methods: {
    setupData() {
      if (this.detail) {
        const {
          genderList,
          agencyList,
          typeOfWorkList,
          levelList,
          personalityRuleList,
          interestRuleList,
          favouriteSportRuleList,
          consciousnessPracticeRuleList,
          locallyRuleList,
          languageRuleList,
          vegetarianRuleList,
          workingHourRuleList,
          levelOfDiabetesRuleList,
          depthExperienceRuleList,
          religionRuleList,
          positionList,
          roleList,
          nationId,
          nation,
          provinceId,
          province,
          districtId,
          district,
          wardId,
          ward,
          accountRule,
          genderRuleList,
          fullName,
          dateOfBirth,
          introduction,
          phoneNumber,
          secondPhoneNumber,
          facebookUrl,
          email,
          url,
          avatar,
          address,
          education,
          referralCode,
          managerList,
          username,
          active,
        } = cloneDeep(this.detail);

        //Account Info: Dropdown values
        this.sexes = genderList;
        this.agencies = this.converDropdownValue(agencyList);
        this.typesOfWork = typeOfWorkList;
        this.levels = levelList;
        this.coaches = managerList;

        // Account Role: Dropdown values
        this.personalities = personalityRuleList;
        this.interests = interestRuleList;
        this.sports = this.converDropdownValue(favouriteSportRuleList);
        this.consciousnesses = consciousnessPracticeRuleList;
        this.regions = locallyRuleList;
        this.languages = languageRuleList;
        this.vegetarians = vegetarianRuleList;
        this.workingTimes = workingHourRuleList;
        this.diabetesLevels = levelOfDiabetesRuleList;
        this.experiences = depthExperienceRuleList;
        this.religions = this.converDropdownValue(religionRuleList);
        this.positions = positionList.map((el, i) => ({ ...el, type: i + 1 }));
        this.roles = roleList;
        this.form.referralCode = referralCode;

        if (this.id) {
          // value textbox
          this.form.name = fullName;
          this.form.username = username;
          this.form.brithday = this.convertTimestampToDate(dateOfBirth);
          this.form.introduction = introduction;
          this.form.firstPhoneNumber = phoneNumber.split('+84').join('0');
          this.form.secondPhoneNumber = secondPhoneNumber
            ? secondPhoneNumber.split('+84').join('0')
            : null;
          this.form.facebookUrl = facebookUrl;
          this.form.email = email;
          this.form.url = url;
          this.form.address = address;
          this.form.qualification = education;

          if (!active) {
            this.form.status = {
              text: 'Inactive',
              value: 0,
            };
          }

          this.accountRuleId = accountRule?.id;

          // value image
          this.preview = avatar?.url;

          // value dropdown
          this.form.sex = genderList.find((el) => el.selected);
          this.form.position = positionList
            .map((el, i) => ({ ...el, type: i + 1 }))
            .find((el) => el.selected);
          this.form.agency = agencyList.find((el) => el.selected);
          this.form.role = roleList.filter((el) => el.selected);
          this.form.coachManager = managerList.filter((el) => el.selected);

          // The position is coach, head coach, coach manager
          if (this.showAccountRules) {
            // account infomations
            this.form.level = levelList.find((el) => el.selected);
            this.form.typeOfWork = typeOfWorkList.find((el) => el.selected);

            // account rules
            this.form.startAge = this.startAges.find(
              (el) => Number(el.value) === accountRule?.fromAge,
            );
            this.form.endAge = this.endAges.find(
              (el) => Number(el.value) === accountRule?.toAge,
            );
            this.form.memberQuantity = this.memberQuantities.find(
              (el) => Number(el.value) === accountRule.amount,
            );
            this.form.selectedSexes = genderRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedPersonalities = personalityRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedInterests = interestRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedSports = favouriteSportRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedConsciousnesses =
              consciousnessPracticeRuleList.filter((el) => el.selected);
            this.form.selectedRegions = locallyRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedLanguages = languageRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedReligions = religionRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedVegetarians = vegetarianRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedWorkingTimes = workingHourRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedDiabetesLevels = levelOfDiabetesRuleList.filter(
              (el) => el.selected,
            );
            this.form.selectedExperiences = depthExperienceRuleList.filter(
              (el) => el.selected,
            );
          }

          this.form.country = {
            id: nationId,
            name: nation,
          };
          this.form.province = {
            id: provinceId,
            name: province,
          };
          this.form.district = {
            id: districtId,
            name: district,
          };
          this.form.ward = {
            id: wardId,
            name: ward,
          };
        }
      }
    },
    converDropdownValue(array) {
      if (!array || !array.length) return [];
      return array.map((el) => ({ text: el.text, value: el.value }));
    },
    addTags(newTag, options, value) {
      const tag = {
        text: newTag,
        value: this.$uuid.v1(),
        isAddNew: true,
      };
      this[options].push(tag);
      this.form[value] = tag;
    },
    addMultipleTags(newTag, options, value) {
      const tag = {
        text: newTag,
        value: this.$uuid.v1(),
        isAddNew: true,
      };
      this[options].push(tag);
      this.form[value].push(tag);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    onUpload() {
      if (!this.file) return;
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    selectProvince() {
      this.form.district = null;
      this.form.ward = null;
      this.resetDropdown('province');
    },
    selectDistrict() {
      this.form.ward = null;
      this.resetDropdown('district');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit(true);
        }
        return;
      });
    },
    submit(active) {
      const params = {
        fullName: this.form.name,
        phoneNumber: this.form.firstPhoneNumber,
        secondPhoneNumber: this.form.secondPhoneNumber,
        gender: Number(this.form.sex.value),
        nationId: this.form.country.id,
        provinceId: this.form.province.id,
        districtId: this.form.district?.id || null,
        wardId: this.form.ward?.id || null,
        email: this.form.email,
        dateOfBirth: this.convertDateToTimestamp(this.form.brithday),
        address: this.form.address,
        password: !this.isUpdate && this.form.password,
        introduction: this.form.introduction,
        facebookUrl: this.form.facebookUrl,
        url: this.form.url,
        username: this.form.username,
        active: active,
        education: this.form.qualification,
        managerId: this.form.coachManager?.value,
        referralCode: this.form.referralCode,
        accountPositionMappings: this.getParamsPositionTags(),
        accountRoles: this.getParamsRoleTags(),
      };
      if (this.showAccountRules) {
        params['accountRule'] = {
          fromAge: Number(this.form.startAge.value),
          toAge: Number(this.form.endAge.value),
          amount: Number(this.form.memberQuantity.value),
          accountRuleTypeMappings: this.getParamsAccountRules(),
          accountRuleTagMappings: this.getAccountRuleMappings(),
        };
        if (this.isUpdate) {
          params.accountRule.id = this.accountRuleId;
        }
        params['level'] = Number(this.form.level.value);
        params['typeOfWork'] = Number(this.form.typeOfWork.value);
        params['education'] = this.form.qualification;
      } else {
        params['accountRule'] = {
          accountRuleTagMappings: this.getAccountRuleMappings(),
        };
      }
      if (this.file) {
        params['file'] = this.file;
      }
      if (this.isUpdate) {
        params['id'] = this.id;
      }
      this.$emit('submit', params);
    },
    generateQrCode() {
      if (!this.form.referralCode) return;
      const options = {
        width: 200,
        height: 200,
      };

      const callback = (error) => error && console.error('QR CODE:', error);
      const value = `https://diab.com.vn/?${this.form.referralCode}`;

      QRCode.toCanvas(this.$refs.qrcodeCanvas, value, options, callback);
    },
    getAccountRuleMappings() {
      let tagMappings = [];

      const getBeforeAndAfterValue = (
        defaultTags,
        beforeSubmitTags,
        selectedTags,
        type,
      ) => {
        const newTagOptions = beforeSubmitTags.filter((el) => el.isAddNew);
        const originalTagOptions = defaultTags.filter((el) => el.selected);
        const tagMapping = this.getTagsParams(
          this.converDropdownValue(originalTagOptions),
          this.converDropdownValue(
            isArray(selectedTags) ? selectedTags : [selectedTags],
          ),
          this.converDropdownValue(newTagOptions),
          type,
        );
        tagMappings = [...tagMappings, ...tagMapping];
      };

      let arrayTagMappings = [
        {
          default: this.detail.agencyList,
          type: TAG_TYPE.AGENCY,
          selected: this.form.agency || [],
          beforeSubmit: this.agencies,
        },
      ];

      if (this.showAccountRules) {
        const arr = [
          {
            default: this.detail.favouriteSportRuleList,
            type: TAG_TYPE.SPORT,
            selected: this.form.selectedSports,
            beforeSubmit: this.sports,
          },
          {
            default: this.detail.religionRuleList,
            type: TAG_TYPE.RELIGION,
            selected: this.form.selectedReligions,
            beforeSubmit: this.religions,
          },
        ];
        arrayTagMappings = [...arrayTagMappings, ...arr];
      }
      arrayTagMappings.forEach((el) => {
        getBeforeAndAfterValue(
          el.default,
          el.beforeSubmit,
          el.selected,
          el.type,
        );
      });

      return tagMappings;
    },
    getTagsParams(defaultTags, selectedTags, newTagsOptions, tagType) {
      let tagParams = [];
      const tagParamsPush = (array, status, type = 1) => {
        array.forEach((tag) => {
          let params = {
            ...(type && {
              accountRuleId: this.accountRuleId,
              tagId: tag.value,
            }),
            tag: !type
              ? {
                  name: tag.text,
                  type: tagType,
                }
              : null,
            modelStatus: status,
          };
          tagParams.push(this.removeEmpty(params));
        });
      };

      const [commonTags, uniqueOriginalTags, existedTags, createdTags] =
        this.getArrayWithStatus(defaultTags, selectedTags, newTagsOptions);

      const params = [
        {
          value: commonTags,
          status: MODAL_STATUS.UNCHANGE,
          type: 1,
        },
        {
          value: uniqueOriginalTags,
          status: MODAL_STATUS.DELETE,
          type: 1,
        },
        {
          value: existedTags,
          status: MODAL_STATUS.NEW,
          type: 1,
        },
        {
          value: createdTags,
          status: MODAL_STATUS.NEW,
          type: 0,
        },
      ];

      params.forEach((el) => {
        tagParamsPush(el.value, el.status, el.type);
      });

      return tagParams;
    },
    getParamsPositionTags() {
      const defaultTags = this.detail.positionList.filter((el) => el.selected);
      const newTagsOptions = this.positions.filter((el) => el.isAddNew);
      const selectedTags = [this.form.position];
      let tagParams = [];
      const tagParamsPush = (array, status, type = 1) => {
        array.forEach((tag) => {
          let params = {
            ...(type && {
              accountId: this.id,
              positionId: tag.value,
            }),
            position: !type
              ? {
                  name: tag.text,
                }
              : null,
            modelStatus: status,
          };
          tagParams.push(this.removeEmpty(params));
        });
      };

      const [commonTags, uniqueOriginalTags, existedTags, createdTags] =
        this.getArrayWithStatus(
          this.converDropdownValue(defaultTags),
          this.converDropdownValue(selectedTags),
          this.converDropdownValue(newTagsOptions),
        );

      const params = [
        {
          value: commonTags,
          status: MODAL_STATUS.UNCHANGE,
          type: 1,
        },
        {
          value: uniqueOriginalTags,
          status: MODAL_STATUS.DELETE,
          type: 1,
        },
        {
          value: existedTags,
          status: MODAL_STATUS.NEW,
          type: 1,
        },
        {
          value: createdTags,
          status: MODAL_STATUS.NEW,
          type: 0,
        },
      ];

      params.forEach((el) => {
        tagParamsPush(el.value, el.status, el.type);
      });

      return tagParams;
    },
    getParamsRoleTags() {
      const defaultTags = this.detail.roleList.filter((el) => el.selected);
      const newTagsOptions = this.roles.filter((el) => el.isAddNew);
      const selectedTags = this.form.role;
      let tagParams = [];
      const tagParamsPush = (array, status, type = 1) => {
        array.forEach((tag) => {
          let params = {
            ...(type && {
              accountId: this.id,
            }),
            roleId: tag.value,
            modelStatus: status,
          };
          tagParams.push(this.removeEmpty(params));
        });
      };

      const [commonTags, uniqueOriginalTags, existedTags, createdTags] =
        this.getArrayWithStatus(
          this.converDropdownValue(defaultTags),
          this.converDropdownValue(selectedTags),
          this.converDropdownValue(newTagsOptions),
        );

      const params = [
        {
          value: commonTags,
          status: MODAL_STATUS.UNCHANGE,
          type: 1,
        },
        {
          value: uniqueOriginalTags,
          status: MODAL_STATUS.DELETE,
          type: 1,
        },
        {
          value: existedTags,
          status: MODAL_STATUS.NEW,
          type: 1,
        },
        {
          value: createdTags,
          status: MODAL_STATUS.NEW,
          type: 0,
        },
      ];

      params.forEach((el) => {
        tagParamsPush(el.value, el.status, el.type);
      });

      return tagParams;
    },
    getParamsAccountRules() {
      let accountRuleParams = [];
      const accountRulePush = (array, status, ruleType) => {
        array.forEach((rule) => {
          const params = {
            ruleType,
            value: Number(rule.value),
            modelStatus: status,
            accountRuleId: this.accountRuleId,
            id: rule.id,
          };
          accountRuleParams.push(this.removeEmpty(params));
        });
      };
      const accountRules = [
        {
          value: this.form.selectedSexes,
          type: ACCOUNT_RULE_TYPE.GENDER,
          default: this.getAccountRuleMappingsDefault(ACCOUNT_RULE_TYPE.GENDER),
        },
        {
          value: this.form.selectedPersonalities,
          type: ACCOUNT_RULE_TYPE.PERSONALITY,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.PERSONALITY,
          ),
        },
        {
          value: this.form.selectedInterests,
          type: ACCOUNT_RULE_TYPE.INTEREST,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.INTEREST,
          ),
        },
        {
          value: this.form.selectedConsciousnesses,
          type: ACCOUNT_RULE_TYPE.CONSCIOUSNESS_PRACTICE,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.CONSCIOUSNESS_PRACTICE,
          ),
        },
        {
          value: this.form.selectedRegions,
          type: ACCOUNT_RULE_TYPE.LOCALLY,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.LOCALLY,
          ),
        },
        {
          value: this.form.selectedLanguages,
          type: ACCOUNT_RULE_TYPE.LANGUAGE,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.LANGUAGE,
          ),
        },
        {
          value: this.form.selectedVegetarians,
          type: ACCOUNT_RULE_TYPE.VAGETARIAN,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.VAGETARIAN,
          ),
        },
        {
          value: this.form.selectedWorkingTimes,
          type: ACCOUNT_RULE_TYPE.WORKING_HOURS,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.WORKING_HOURS,
          ),
        },
        {
          value: this.form.selectedDiabetesLevels,
          type: ACCOUNT_RULE_TYPE.LEVEL_DIABETES,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.LEVEL_DIABETES,
          ),
        },
        {
          value: this.form.selectedExperiences,
          type: ACCOUNT_RULE_TYPE.DEPTH_EXPERIENCE,
          default: this.getAccountRuleMappingsDefault(
            ACCOUNT_RULE_TYPE.DEPTH_EXPERIENCE,
          ),
        },
      ];
      accountRules.forEach((el) => {
        const [commonItems, deletedItems, addNewItems] =
          this.getAccountRulesWithValue(el.default, el.value);
        const params = [
          {
            value: commonItems,
            status: MODAL_STATUS.UNCHANGE,
          },
          {
            value: deletedItems,
            status: MODAL_STATUS.DELETE,
          },
          {
            value: addNewItems,
            status: MODAL_STATUS.NEW,
          },
        ];

        params.forEach((item) => {
          accountRulePush(item.value, item.status, el.type);
        });
      });

      return accountRuleParams;
    },
    getAccountRuleMappingsDefault(type) {
      if (!this.isUpdate) return [];
      const result =
        this.detail.accountRule?.accountRuleTypeMappings.filter(
          (el) => el.ruleType === type,
        ) || [];
      return result;
    },
    getAccountRulesWithValue(defaultItems, beforeSubmitItem) {
      beforeSubmitItem = beforeSubmitItem.map((el) => ({
        ...el,
        value: Number(el.value),
      }));
      //Get common Items of two Items array => Status: 0 (Unchanged)
      let commonItems = intersectionBy(defaultItems, beforeSubmitItem, 'value');
      //Get Items only in original Items array => Status: 2 (Deleted)
      let deletedItems = differenceBy(defaultItems, beforeSubmitItem, 'value');
      //Get Items only in current Items array
      let addNewItems = differenceBy(beforeSubmitItem, defaultItems, 'value');
      return [commonItems, deletedItems, addNewItems];
    },
    getArrayWithStatus(defaultTags, selectedTags, newTagsOptions) {
      //Get common tags of two tags array => Status: 0 (Unchanged)
      let commonTags = intersectionWith(defaultTags, selectedTags, isEqual);

      //Get tags only in original tags array => Status: 2 (Deleted)
      let uniqueOriginalTags = differenceWith(
        defaultTags,
        selectedTags,
        isEqual,
      );

      //Get tags only in current tags array
      let uniqueCurrentTags = differenceWith(
        selectedTags,
        defaultTags,
        isEqual,
      );

      // Status: 3 (new mapping)
      let existedTags = differenceWith(
        uniqueCurrentTags,
        newTagsOptions,
        isEqual,
      );

      // Status: 3 (new tag)
      let createdTags = intersectionWith(
        uniqueCurrentTags,
        newTagsOptions,
        isEqual,
      );

      return [commonTags, uniqueOriginalTags, existedTags, createdTags];
    },
    resetDropdown(field) {
      this.$validator.reset({ name: field });
    },
    async selectedStartAge(age) {
      this.form.startAge = age;
      await this.$validator.validate('startAge');
      this.endAges = this.generalAges.filter(
        (el) => Number(el.value) >= Number(age.value),
      );
    },
    async selectedEndAge(age) {
      this.form.endAge = age;
      await this.$validator.validate('endAge');
      this.startAges = this.generalAges.filter(
        (el) => Number(el.value) <= Number(age.value),
      );
    },
    downloadQrCode() {
      let link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = document.querySelector('#qr-code').toDataURL('image/png');
      link.click();
    },
    selectOptions(event, type, options) {
      if (!event || !event.length) return;

      let selectAll = event.find((el) => el.value === '-1');

      if (!selectAll) return;

      const newOptions = options.filter((el) => el.value !== '-1');

      switch (type) {
        case 'personality':
          this.form.selectedPersonalities = newOptions;
          break;
        case 'interests':
          this.form.selectedInterests = newOptions;
          break;
        case 'sport':
          this.form.selectedSports = newOptions;
          break;
        case 'consciousness':
          this.form.selectedConsciousnesses = newOptions;
          break;
        case 'regions':
          this.form.selectedRegions = newOptions;
          break;
        case 'language':
          this.form.selectedLanguages = newOptions;
          break;
        case 'religions':
          this.form.selectedReligions = newOptions;
          break;
        case 'vegetarian':
          this.form.selectedVegetarians = newOptions;
          break;
        case 'workingTime':
          this.form.selectedWorkingTimes = newOptions;
          break;
        case 'diabetesLevels':
          this.form.selectedDiabetesLevels = newOptions;
          break;

        default:
          this.form.selectedExperiences = newOptions;
          break;
      }
    },
    searchDebounce(value) {
      if (value.length < 6) return;
      this.checkValidReferralCode(value);
    },
    async checkValidReferralCode(code) {
      try {
        const res = await this.$api.post(
          '/Admin/Account/portal/checkduplicate-refcode',
          {
            refCode: code,
          },
        );

        if (res.error) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: res.error.message,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.image-upload {
  width: 160px;
  height: 160px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}

.wrap-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
}

.left-site {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}

.blank {
  height: 16px;
  background: #f2f3f7;
  margin: 0 -30px 30px;
}
</style>
